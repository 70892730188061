.sertificates {
	padding-top: 60px;
	padding-bottom: 80px;
	width: 100%;
	color: #000;
	font-family: Roboto, Helvetica, Arial, sans-serif;

	.inner {
		max-width: 1440px;
		padding: 0 120px;
		position: relative;
		width: 100%;
		margin: 0 auto;
		min-width: 320px;

		.titleContainer {
			margin-bottom: 40px;
			text-align: center;

			.title {
				font-size: 24px;
				position: relative;
				display: inline-block;
				margin: 0;
				font-weight: 400;
			}
			.title::after {
				content: '';
				width: 180px;
				bottom: -5px;
				position: absolute;
				height: 2px;
				left: 50%;
				-webkit-transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				background-color: #d60623;
			}
		}
		.listWrapper {
			max-width: 1200px;
			margin: 0 auto;

			.item {
				width: auto;
				margin: 0 25px;
				position: relative;
				transition: transform 0.3s ease, -webkit-transform 0.3s ease,
					-moz-transform 0.3s ease;
				border-radius: 9px;
				border: 1px solid rgba(151, 151, 151, 0.3);
				outline: 0;
				.image {
					cursor: pointer;
					.img {
						display: block;
						width: auto;
						max-width: 100%;
						height: auto;
						max-height: 100%;
						margin: 0 auto;
						border-radius: 9px;
					}
				}

				.label {
					padding: 14px 5px;
					font-size: 18px;
					color: #000;
					font-weight: 400;
					text-decoration: none;
					text-align: center;
				}
			}
			.item:hover {
				background: #dddddd;
			}
		}
	}
}

@media (max-width: 1355px) {
	.sertificates {
		.inner {
			.listWrapper {
				.item {
					.label {
						font-size: 15px;
					}
				}
			}
		}
	}
}

@media (max-width: 1240px) {
	.sertificates {
		padding: 24px 0 40px;
		.inner {
			max-width: 900px;
			padding: 0 20px;

			.titleContainer {
				margin-bottom: 30px;

				.title {
					font-size: 18px;
				}
				.title::after {
					width: 120px;
				}
			}
			.listWrapper {
				max-width: 740px;

				.item {
					margin: 0 5px;

					.label {
						padding: 10px 5px;
						font-size: 13px;
					}
				}
			}
		}
	}
}


