.disorders {
	width: 100%;
	padding: 24px 0 18px;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	color: #000;
	padding-top: 60px;
	padding-bottom: 40px;

	.inner {
		position: relative;
		width: 100%;
		margin: 0 auto;
		min-width: 320px;
		max-width: 1440px;
		padding: 0 120px;

		.titleContainer {
			text-align: center;
			margin-bottom: 60px;

			.title {
				position: relative;
				display: inline-block;
				margin: 0;
				font-family: Roboto, Helvetica, Arial, sans-serif;
				color: #000;
				font-size: 24px;
				font-weight: 400;
			}
			.title::after {
				content: '';
				position: absolute;
				width: 180px;
				height: 2px;
				bottom: -5px;
				left: 50%;
				-webkit-transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				background-color: #d60724;
			}
		}
		.wrapper {
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 0 50px;

			.list {
				padding: 0;
				list-style: none;
				position: relative;
				width: 45%;
				margin-right: 10%;

				.item {
					position: relative;
					font-weight: 500;
					margin-bottom: 43px;
					padding-left: 35px;
					font-size: 20px;
					line-height: 27px;

					span {
						font-weight: 300;
						font-size: 16px;
					}
				}
				.item::before {
					content: '';
					position: absolute;
					width: 10px;
					height: 10px;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					background-color: #d60724;
					border-radius: 50%;
				}
			}
			.list::before {
				content: '';
				position: absolute;
				height: calc(100% - 60px);
				background-size: 12px 14px;
				width: 12px;
				left: -1px;
				top: 12px;
				background-image: url(../../assets/images/bg-pattern_s.png);
				background-repeat: repeat-y;
			}
		}
	}
}

@media (max-width: 1240px) {
	.disorders {
		padding-top: 0;
		padding-bottom: 0;

		.inner {
			max-width: 900px;
			padding: 0 20px;

			.titleContainer {
				margin-bottom: 30px;
				margin-top: 30px;

				.title {
					font-size: 18px;
				}
				.title::after {
					width: 120px;
				}
			}
			.wrapper {
				.list {
					.item {
						margin-bottom: 22px;
						padding-left: 15px;
						font-size: 13px;
						line-height: 18px;

						span {
							font-size: 10px;
						}
					}
					.item::before {
						width: 7px;
						height: 7px;
					}
				}
				.list::before {
					height: calc(100% - 45px);
					background-size: auto;
					width: 9px;
					left: -1px;
					top: 7px;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.disorders {
		padding-top: 0;
		padding-bottom: 0;
		padding: 24px 0 18px;

		.inner {
			max-width: 480px;
			padding: 0 10px;

			.titleContainer {
				margin-bottom: 30px;
				margin-top: 0;

				.title {
					font-size: 18px;
				}
				.title::after {
					width: 120px;
				}
			}
			.wrapper {
				display: block;
				padding: 0 7px;

				.list {
					width: 100%;

					.item::before {
						width: 7px;
						height: 7px;
					}
				}
				.list:before {
					height: calc(100% + 15px);
				}
				.list:last-child:before {
					height: calc(100% - 15px);
				}
			}
		}
	}
}
