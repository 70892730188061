.sentForm {
	margin-top: 120px;
	width: 100%;
	padding: 10px 40px;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	color: #000;
	background: #f9f9f9;

	.inner {
		max-width: 1440px;
		padding: 0 120px;
		position: relative;
		width: 100%;
		margin: 0 auto;
		padding-top: 20px;

		.row {
			position: relative;
			height: auto;
			min-height: 450px;
			padding: 0 20px;
			width: 100%;

			.buttonBack {
				position: absolute;
				padding: 5px 10px;
				font-size: 16px;
				font-weight: 500;
				color: black;
				text-decoration: none;
				top: 5px;
				left: 40px;
				background-color: rgb(235, 235, 235);
			}
			.wrapper {
				width: 100%;
				height: 100%;
				max-width: 1120px;
				margin: 0 auto;

				.container {
					display: flex;
					height: 100%;
					overflow: hidden;

					.col {
						width: 60%;
						padding: 60px 0 30px;

						.title {
							font-size: 25px;
							font-weight: 500;
							margin: 0;
							margin-bottom: 20px;
						}

						.subtitle {
							font-size: 23px;
							font-weight: 400;
							margin: 0;
							margin-bottom: 60px;
						}

						.appeal {
							font-size: 22px;
							font-weight: 300;
							margin: 0;
							margin-bottom: 20px;
						}

						.socials {
							margin-bottom: 10px;
							display: flex;

							.link {
								display: flex;
								align-items: center;
								width: max-content;

								text-decoration: none;
								line-height: 28px;
								font-size: 18px;
								border-radius: 4px;
								height: 32px;
								margin: 0 6px 12px;
								padding: 10px 5px;
								background: rgba(231, 231, 231, 0.8);

								.icon {
									color: #000;
									fill: #526e8f;
									white-space: nowrap;
									position: relative;
									text-align: left;
									display: inline-block;
									line-height: 28px;
									font-size: 18px;
									margin-right: 10px;
									margin-left: 5px;
									width: 32px;
									height: 32px;

									.imgIcon {
										left: 4px;
										top: 4px;
										width: 100%;
										height: 100%;
									}
								}
								.button {
									color: #000;
								}
							}
							.link:hover {
								background: rgba(211, 218, 227, 0.8);
							}
						}

						.text {
							width: 100%;
							max-width: 380px;
							font-size: 16px;
							font-weight: 300;
							line-height: 1.5;

							em {
								font-size: 16px;
								font-weight: 400;
							}
						}
					}

					.img {
						position: absolute;
						right: 0;
						bottom: 0;
						overflow: hidden;
						width: 40%;
						height: 90%;
						margin-left: auto;
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center bottom;
						background-image: url(../../assets/images/sp-woman.png);
					}
				}
			}
		}

		.wrapper {
		}
	}
}

@media (max-width: 1240px) {
	.sentForm {
	margin-top: 70px;
	width: 100%;
	padding: 0;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	color: #000;
	background: #f9f9f9;

	.inner {
		max-width: 900px;
		padding: 0;
		position: relative;
		width: 100%;
		margin: 0 auto;
		padding-top: 20px;

		.row {
			position: relative;
			height: auto;
			min-height: 450px;
			padding: 0 20px;
			width: 100%;

			.buttonBack {
				position: absolute;
				padding: 5px 10px;
				font-size: 16px;
				font-weight: 500;
				color: black;
				text-decoration: none;
				top: 5px;
				left: 20px;
				background-color: rgb(235, 235, 235);
			}
			.wrapper {
				width: 100%;
				height: 100%;
				max-width: 1120px;
				margin: 0 auto;

				.container {
					display: flex;
					height: 100%;
					overflow: hidden;

					.col {
						width: 100%;
						padding: 60px 0 30px;

						.title {
							font-size: 25px;
							font-weight: 500;
							margin: 0;
							margin-bottom: 20px;
						}

						.subtitle {
							font-size: 23px;
							font-weight: 400;
							margin: 0;
							margin-bottom: 60px;
						}

						.appeal {
							font-size: 22px;
							font-weight: 300;
							margin: 0;
							margin-bottom: 20px;
						}

						.socials {
							margin-bottom: 10px;
							display: flex;

							.link {
								display: flex;
								align-items: center;
								width: max-content;

								text-decoration: none;
								line-height: 28px;
								font-size: 18px;
								border-radius: 4px;
								height: 32px;
								margin: 0 6px 12px;
								padding: 10px 5px;
								background: rgba(231, 231, 231, 0.8);

								.icon {
									color: #000;
									fill: #526e8f;
									white-space: nowrap;
									position: relative;
									text-align: left;
									display: inline-block;
									line-height: 28px;
									font-size: 18px;
									margin-right: 10px;
									margin-left: 5px;
									width: 32px;
									height: 32px;

									.imgIcon {
										left: 4px;
										top: 4px;
										width: 100%;
										height: 100%;
									}
								}
								.button {
									color: #000;
								}
							}
							.link:hover {
								background: rgba(211, 218, 227, 0.8);
							}
						}

						.text {
							width: 100%;
							max-width: 380px;
							font-size: 16px;
							font-weight: 300;
							line-height: 1.5;

							em {
								font-size: 16px;
								font-weight: 400;
							}
						}
					}

					.img {
						position: absolute;
						right: 0;
						bottom: 0;
						overflow: hidden;
						width: 40%;
						height: 90%;
						margin-left: auto;
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center bottom;
						background-image: url(../../assets/images/sp-woman.png);
					}
				}
			}
		}

		.wrapper {
		}
	}
}
}

@media (max-width: 768px) {
	.sentForm {
	margin-top: 70px;

	.inner {
		max-width: 768px;

		.row {

			.buttonBack {
				position: absolute;
				padding: 5px 10px;
				font-size: 16px;
				font-weight: 500;
				color: black;
				text-decoration: none;
				top: 5px;
				left: 0;
				background-color: rgb(235, 235, 235);
			}
			.wrapper {
				width: 100%;
				height: 100%;
				max-width: 1120px;
				margin: 0 auto;

				.container {
					display: flex;
					height: 100%;
					overflow: hidden;

					.col {
						width: 100%;
						padding: 60px 0 30px;

						.title {
							font-size: 25px;
							font-weight: 500;
							margin: 0;
							margin-bottom: 20px;
						}

						.subtitle {
							font-size: 23px;
							font-weight: 400;
							margin: 0;
							margin-bottom: 60px;
						}

						.appeal {
							font-size: 22px;
							font-weight: 300;
							margin: 0;
							margin-bottom: 20px;
						}

						.socials {
							margin-bottom: 10px;
							display: flex;
							flex-wrap: wrap;

							.link {
								display: flex;
								align-items: center;
								width: max-content;

								text-decoration: none;
								line-height: 28px;
								font-size: 18px;
								border-radius: 4px;
								height: 32px;
								margin: 0 6px 12px;
								padding: 10px 5px;
								background: rgba(231, 231, 231, 0.8);

								.icon {
									color: #000;
									fill: #526e8f;
									white-space: nowrap;
									position: relative;
									text-align: left;
									display: inline-block;
									line-height: 28px;
									font-size: 18px;
									margin-right: 10px;
									margin-left: 5px;
									width: 32px;
									height: 32px;

									.imgIcon {
										left: 4px;
										top: 4px;
										width: 100%;
										height: 100%;
									}
								}
								.button {
									color: #000;
								}
							}
							.link:hover {
								background: rgba(211, 218, 227, 0.8);
							}
						}

						.text {
							width: 100%;
							max-width: 380px;
							font-size: 16px;
							font-weight: 300;
							line-height: 1.5;

							em {
								font-size: 16px;
								font-weight: 400;
							}
						}
					}

					.img {
						display: none;
					}
				}
			}
		}

		.wrapper {
		}
	}
}
}