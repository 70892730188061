.slide {
	position: relative;
	width: 920px;
	outline: 0;
	padding: 0 20px;
	float: left;
	height: 100%;
	min-height: 1px;
	background: #ffffff;

	.testimonial {
		display: flex;

		.testimonialImg {
			width: 360px;
			margin: 0;
			margin-left: 10px;
			max-width: none;
			padding: 0;
			order: 1;
			flex: 0 0 auto;

			.image {
				display: block;
				width: auto;
				max-width: 100%;
				height: auto;
				max-height: 100%;
				margin: 0 auto;
			}
		}

		.content {
			max-height: 400px;
			padding-right: 40px;
			width: 50%;
			flex: 1 1 auto;
			padding-bottom: 40px;
			display: flex;
			flex-flow: column nowrap;
			position: relative;
			padding-bottom: 40px;
			justify-content: flex-start;
			align-content: flex-start;
			align-items: flex-start;
			overflow: auto;

			.title {
				margin: 0 0 5px;
				margin-bottom: 10px;
				font-size: 24px;
				font-weight: 500;
			}
			.text {
				font-size: 18px;
				margin-bottom: 20px;
				font-weight: 300;
			}
			.footer {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: auto;

				.author {
					.name {
						font-size: 24px;
						font-weight: 500;
					}
					.status {
						font-size: 18px;
						font-weight: 300;
					}
				}
				.linkSocial {
					background-image: url(../../../assets/images/icons/instagram_hover.svg);
					width: 30px;
					height: 30px;
					margin-left: 20px;
					flex: 0 0 auto;
					background-size: cover;
					background-color: transparent;
				}
			}
		}
		.content::-webkit-scrollbar {
			width: 4px;
			background-color: #f9f9fd;
		}

		.content::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: #d60623;
		}

		.content::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
			border-radius: 10px;
			background-color: #f9f9fd;
		}
	}
}

@media (max-width: 1240px) {
	.slide {
		width: 608px;

		.testimonial {
			display: flex;

			.testimonialImg {
				width: 200px;
			}

			.content {
				max-height: 250px;

				.title {
					font-size: 16px;
				}

				.text {
					font-size: 12px;
				}
				.footer {
					.author {
						.name {
							font-size: 14px;
							font-weight: 500;
						}
						.status {
							font-size: 10px;
							font-weight: 300;
						}
					}
					.linkSocial {
						width: 22px;
						height: 22px;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.slide {
		width: auto;

		.testimonial {
			display: block;

			.testimonialImg {
				margin: 0 auto 18px;
				max-width: 300px;
				padding: 0;
			}

			.content {
				position: relative;
				max-height: 250px;
				padding-bottom: 40px;
				width: 100%;

				.title {
					font-size: 16px;
				}
				.text {
					font-size: 12px;
				}
				.footer {
					.author {
						.name {
							font-size: 14px;
						}
						.status {
							font-size: 10px;
						}
					}
					.linkSocial {
						width: 22px;
						height: 22px;
					}
				}
			}
		}
	}
}
