.notFoundComponent {
	margin-top: 25%;
	margin-bottom: 25%;
	width: 100%;
	padding: 0 40px;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	color: #000;

	.inner {
		max-width: 1440px;
		padding: 0 120px;
		position: relative;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.titleContainer {
			margin-bottom: 25px;

			.title {
				font-size: 36px;
				text-align: center;
			}
		}

		a {
			text-decoration: none;
			.button {
				border-radius: 7px;
				box-shadow: 5px 2px 27px rgb(215 0 0 / 50%);
				background-color: #d60623;
				width: 386px;
				padding: 15px 20px;
				margin-bottom: 11px;
				text-align: center;
				cursor: pointer;
				color: #fff;
				font-size: 20px;
				font-weight: 700;
				line-height: 40px;
				text-transform: uppercase;
			}
		}

		.button:hover {
			background-color: #a40117;
			outline: 1px solid #d60623;
		}
	}
}

@media (max-width: 1280px) {
	.notFoundComponent {
		margin-top: 40%;
		margin-bottom: 40%;

		.inner {
			max-width: 900px;
			padding: 0 20px;
			position: relative;
		}
	}
}

@media (max-width: 768px) {
	.notFoundComponent {
		margin-top: 50%;
		margin-bottom: 30%;

		.inner {
			max-width: 480px;
			padding: 0 10px;

			.titleContainer {
				margin-bottom: 25px;

				.title {
					font-size: 24px;
				}
			}

			a {
				font-size: 18px;
				.button {
					width: 300px;
				}
			}
		}
	}
}
