.appeal {
	background: #f9f9f9;
	padding: 40px 0;
	width: 100%;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	color: #000;

	.inner {
		margin: 0 auto;
		width: 840px;
		padding: 0;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

		.heading {
			padding-left: 70px;
			margin-bottom: 0;
			position: relative;

			.title {
				font-size: 24px;
				margin-bottom: 3px;
				font-weight: 500;
				line-height: 1.15;
			}
		}
		.heading::before {
			content: '';
			position: absolute;
			width: 50px;
			height: 46px;
			top: 5px;
			left: 0;
			background-image: url(../../assets/images/icons/heart.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
		.button {
			width: 240px;
			padding: 9px 10px;
			margin: 0;
			position: relative;
			color: #ffffff;
			background-color: #d60724;
			cursor: pointer;
			text-decoration: none;
			border: 1px solid transparent;
			transition: all 300ms ease;
			font-size: 14px;
			font-weight: 700;
			text-align: center;
			text-transform: uppercase;
			border-radius: 2px;
		}
		.button:hover {
			background-color: #8a0517;
		}
	}
}

@media (max-width: 1240px) {
	.appeal {
		padding-top: 24px;
		padding-bottom: 24px;

		.inner {
			width: 696px;

			.heading {
				padding-left: 46px;

				.title {
					font-size: 16px;
				}
			}
			.heading::before {
				width: 34px;
				height: 32px;
				top: 2px;
			}
			.button {
				width: 190px;
				padding: 5px 10px;
			}
		}
	}
}

@media (max-width: 768px) {
	.appeal {
		padding-top: 24px;
		padding-bottom: 24px;

		.inner {
			width: 320px;
			margin: 0 auto;
			padding: 0 10px;
			flex-direction: column;

			.heading {
				padding-left: 46px;
				margin-bottom: 20px;
			}
		}
	}
}
