.map {
	z-index: 5;
	height: 600px;
	width: 100%;
	position: relative;

	.inner {
		max-width: 1440px;
		padding: 0 120px;
		width: 100%;
		height: 100%;
		position: absolute;
		pointer-events: none;
		left: 50%;
		transform: translateX(-50%);
		margin: 0 auto;
		min-width: 320px;
		z-index: 10;

		.contacts {
			width: 350px;
			right: 120px;
			position: absolute;
			z-index: 25;
			top: 0;
			max-width: 360px;
			height: 100%;
			display: flex;
			flex-flow: column nowrap;
			padding: 20px 10px;
			pointer-events: none;

			.wrapper {
				pointer-events: all;
				margin-top: 12px;

				.info {
					width: 100%;
					padding: 16px 12px;
					background: #fff;
					box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
					border-radius: 13px;

					.container {
						position: relative;
						padding-left: 28px;
						padding-bottom: 24px;
						font-family: Roboto, Helvetica, Arial, sans-serif;

						.containerImg {
							position: absolute;
							width: 16px;
							height: 16px;
							top: 2px;
							left: 0;

							.image {
								width: 100%;
								height: 100%;
								fill: #d60623;
							}
						}
						.title {
							margin-bottom: 7px;
							font-size: 16px;
							margin: 0 0 5px;
							color: #000;
							font-weight: 500;
							line-height: 1.2;
						}
						.subTitle {
							font-size: 14px;
							margin: 0;
							font-weight: 300;
							text-decoration: none;
							color: #000;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1280px) {
	.map {
		height: 440px;

		.inner {
			max-width: 900px;
			padding: 0 20px;

			.contacts {
				width: 300px;
				right: 0;

				.wrapper {
					margin-top: 8px;

					.info {
						.container {
							padding-left: 20px;
							padding-bottom: 16px;

							.containerImg {
								width: 13px;
								height: 13px;
								top: 2px;
								left: 0;
							}
							.title {
								font-size: 12px;
							}
							.subTitle {
								font-size: 10px;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.map {
		height: 520px;
		overflow: hidden;

		.inner {
			max-width: 480px;
			padding: 0 10px;

			.contacts {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
        padding: 15px 30px;

				.wrapper {
					margin-top: 0;

					.info {
						.container {
							padding-left: 20px;
							padding-bottom: 16px;

							.containerImg {
								width: 13px;
								height: 13px;
							}
							.title {
								font-size: 12px;
							}
							.subTitle {
								font-size: 10px;
							}
						}
					}
				}
			}
		}
	}
}
