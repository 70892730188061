.background {
	background: #00000085;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);
	z-index: 50;
}
.modal {
	font-family: Roboto, Helvetica, Arial, sans-serif;
	background: #dcdbdb;
	color: #131313;
	border-radius: 6px;
	border: none;
	background-color: #fbfbfb;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 340px;
	height: 342px;
	transform: translate(-50%, -50%);
	box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
	z-index: 50;
	animation: show 500ms ease-in-out;

	.form {
		padding: 5px 20px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;

		.close {
			font-size: 30px;
			font-weight: 500;
			appearance: none;
			align-self: flex-end;
			padding: 0;
			cursor: pointer;
			background: 0 0;
			border: 0;
			color: #000;
			text-shadow: 0 1px 0 #fff;
			opacity: 0.2;
		}

		.input {
			padding: 18px;
			margin-bottom: 6px;
			font-size: 14px;
			width: 100%;
			border-radius: 3px;
			border: 1px solid #dcdcdc;
			background-color: #fff;
		}

		.button {
			font-family: Roboto, Helvetica, Arial, sans-serif;
			margin-top: 40px;
			cursor: pointer;
			padding: 12px 20px;
			color: #fff;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 1;
			font-weight: 900;
			border-radius: 3px;
			border: 1px solid #d60623;
			background-color: #d60623;
			transition: all 0.3s ease;
		}
		.button:hover {
			color: #131313;
			background-color: #fff;
		}
		.button:active {
			color: #131313;
			background-color: #fff;
		}

		.check {
			margin-top: 20px;
			font-size: 14px;
			line-height: 1.42857143;
			color: #333;

			.checkbox {
				position: absolute;
				width: 10px;
				height: 10px;
				left: 22px;
				bottom: 64px;
				z-index: -3;
			}

			.text {
				position: relative;
				display: inline-block;
				width: 100%;
				padding-left: 24px;
				font-size: 12px;
				border-radius: 3px;
				font-weight: 400;
				cursor: pointer;
				color: #9e9e9e;
				line-height: 12px;

				.link {
					color: #337ab7;
				}
			}

			.checkboxStyleActive {
				content: '';
				position: absolute;
				width: 14px;
				height: 14px;
				top: 268px;
				left: 20px;
				border: 1px solid #d60724;
				border-radius: 2px;
				background-color: #fff;
				background-image: url(../../assets/images/icons/galka.svg);
				background-size: contain;
				transition: background-color 0.3s ease;

			}
			.checkboxStyle {
				content: '';
				position: absolute;
				width: 14px;
				height: 14px;
				top: 268px;
				left: 20px;
				border: 1px solid #d60724;
				border-radius: 2px;
				background-color: #fff;
				background-image: none;
				background-size: contain;
				transition: background-color 0.3s ease;

			}
		}
	}
}

@keyframes show {
	from {
		top: 70%;
		opacity: 0;
	}
	to {
		top: 50%;
		opacity: 1;
	}
}
