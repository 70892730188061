.aboutService {
	padding: 60px 0 80px;
	width: 100%;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	color: #000;
	background: #f9f9f9;

	.inner {
		position: relative;
		width: 100%;
		margin: 0 auto;
		min-width: 320px;
		max-width: 1440px;
		padding: 0 120px;

		.titleContainer {
			text-align: center;
			margin: 0 auto 25px;
			margin-bottom: 35px;

			.title {
				font-size: 24px;
				position: relative;
				display: inline-block;
				margin: 0;
				font-family: Roboto, Helvetica, Arial, sans-serif;
				color: #000;
				font-weight: 400;
			}
			.title::after {
				content: '';
				position: absolute;
				width: 180px;
				height: 2px;
				bottom: -5px;
				left: 50%;
				-webkit-transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				background-color: #d60724;
			}
		}
		.list {
			margin: 0 auto;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			max-width: 1200px;
			flex-direction: row;
			flex-wrap: nowrap;

			.item {
				width: 360px;
				margin: 5px;
				margin-bottom: 0;
				display: flex;
				flex-flow: column nowrap;

				.imageContainer {
					width: 100%;
					margin-bottom: 35px;

					.img {
						width: 100%;
						margin: 0 auto;
						border-radius: 5px;
					}
				}
				.heading {
					line-height: 1.2;
					font-weight: 400;
					text-align: center;
					display: flex;
					justify-content: center;
					min-height: 60px;
					margin-bottom: 20px;
					font-size: 20px;
					align-items: flex-start;
				}
				.description {
					font-size: 18px;
					margin: 0;
					font-weight: 300;
					line-height: 1.2;
				}
			}
		}
	}
}

@media (max-width: 1240px) {
	.aboutService {
		padding: 24px 0 40px;

		.inner {
			max-width: 900px;
			padding: 0 20px;

			.titleContainer {
				text-align: center;
				margin-bottom: 0;
				margin: 0 auto 25px;

				.title {
					font-size: 18px;
				}

				.title::after {
					width: 120px;
				}
			}

			.list {
				max-width: 820px;

				.item {
					.imageContainer {
						width: 224px;
						margin-bottom: 10px;
					}
					.heading {
						min-height: 35px;
						margin-bottom: 5px;
						font-size: 14px;
					}
					.description {
						font-size: 12px;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.aboutService {
		padding: 24px 0 40px;

		.inner {
			max-width: 480px;
			min-width: 320px;
			padding: 0 10px;

			.titleContainer{
				margin-bottom: 30px;
				margin-top: 0;
			}
			.list {
				max-width: none;
				display: flex;
				flex-direction: column;
				align-items: center;

				.item {
					width: 224px;
					display: flex;

					.imageContainer {
						width: 224px;
						margin-bottom: 10px;
					}
					.heading {
						min-height: 35px;
						margin-bottom: 5px;
						font-size: 14px;
					}
					.description {
						font-size: 12px;
						margin-bottom: 30px;
					}
				}
			}
		}
	}
}
