details > summary {
	cursor: pointer;
	list-style: none;
	text-decoration: none;
}

details > summary:hover {
	color: #d60623;
}

details > summary:focus {
	color: #d60623;
}

summary::before {
	position: absolute;
	outline: 0;
	background-repeat: no-repeat;
	background-position: center;
	font-size: 0;
	border: none;
	z-index: 6;
	width: 24px;
	height: 38px;
	left: 0;
	top: 50%;
	transform: translateY(-50%) scale(0.5);
	background: url(../../assets/images/arrow_red.svg);
	content: '';
}

details[open] summary::before {
	position: absolute;
	outline: 0;
	background-repeat: no-repeat;
	background-position: center;
	font-size: 0;
	border: none;
	z-index: 6;
	width: 24px;
	height: 38px;
	left: 0;
	top: 50%;
	transform: translateY(-50%) scale(0.5) rotate(90deg);
	background-color: #d60623;
	background: url(../../assets/images/arrow_red.svg);
	content: '';
}

.faq {
	padding-top: 60px;
	padding-bottom: 80px;
	color: #000;
	font-family: Roboto, Helvetica, Arial, sans-serif;

	.inner {
		max-width: 1440px;
		padding: 0 120px;
		position: relative;
		width: 100%;
		margin: 0 auto;
		min-width: 320px;

		.titleContainer {
			margin-bottom: 60px;
			text-align: center;

			.title {
				font-size: 24px;
				position: relative;
				display: inline-block;
				margin: 0;
				font-weight: 400;
			}
			.title::after {
				width: 180px;
				bottom: -5px;
				content: '';
				position: absolute;
				height: 2px;
				left: 50%;
				transform: translateX(-50%);
				background-color: #d60623;
			}
		}
		.list {
			max-width: 1080px;
			padding: 0;
			list-style: none;
			margin: 0 auto;

			.item {
				margin-bottom: 35px;

				.question {
					padding-left: 30px;
					font-size: 20px;
					position: relative;
					margin-bottom: 5px;
					padding: 0 0 0 25px;
					font-weight: 500;
					transition: color 0.3s ease;
				}
				.answer {
					display: block;
					padding-top: 15px;
					padding-left: 30px;
					font-size: 20px;
					font-weight: 400;
					line-height: 1.15;
					color: #353535;
					.text {
						margin: 0 0 5px;
					}
					.answerList {
						margin-bottom: 5px;
						padding: 10px 0 0;
						list-style: none;

						.answerItem {
							position: relative;
							padding-left: 20px;
							margin-bottom: 5px;
						}
						.answerItem::before {
							content: '';
							position: absolute;
							width: 5px;
							height: 5px;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							background-color: #d60623;
							border-radius: 50%;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1240px) {
	.faq {
		padding-top: 25px;
		padding-bottom: 40px;

		.inner {
			max-width: 900px;
			padding: 0 20px;

			.titleContainer {
				margin-bottom: 30px;

				.title {
					font-size: 18px;
				}
				.title::after {
					width: 120px;
				}
			}
			.list {
				max-width: 680px;

				.item {
					margin-bottom: 20px;

					.question {
						font-size: 13px;
					}
					.answer {
						padding-top: 5px;
						padding-left: 25px;
						font-size: 13px;
						margin: 0;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	summary::before {
		transform: translateY(-50%) scale(0.3);
	}

	details[open] summary::before {
		transform: translateY(-50%) scale(0.3) rotate(90deg);
	}

	.faq {
		padding-top: 25px;
		padding-bottom: 40px;

		.inner {
			max-width: 480px;
			padding: 0 10px;

			.titleContainer {
				margin-bottom: 30px;

				.title {
					font-size: 18px;
				}
				.title::after {
					width: 120px;
					bottom: -5px;
					content: '';
					position: absolute;
					height: 2px;
					left: 50%;
					transform: translateX(-50%);
					background-color: #d60623;
				}
			}
			.list {
				max-width: 680px;

				.item {
					margin-bottom: 15px;

					.question {
						padding: 0 0 0 25px;
					}
					.answer {
						padding-top: 5px;
						padding-left: 25px;
						font-size: 13px;
					}
				}
			}
		}
	}
}
