.footer {
	width: 100%;
	padding: 50px 0 60px;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	color: #fff;
	line-height: 1.42857143;
	background-color: #2d2d2d;
	padding-bottom: 50px;

	.inner {
		max-width: 1440px;
		padding: 0 120px;
		position: relative;
		width: 100%;
		margin: 0 auto;
		min-width: 320px;

		.innerContainer {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;
			text-align: center;
			flex-direction: column;
			flex-wrap: nowrap;

			.logoContainer {
				order: -3;
				display: block;
				width: 260px;
				margin-right: 48px;
				margin-bottom: 24px;
				flex: 0 0 auto;

				.logo {
					width: 287px;
				}
			}
			.contacts {
				width: 100%;
				margin: 0 auto 24px;
				text-align: center;

				.title {
					margin: 0 0 12px;
					font-size: 16px;
					font-weight: 300;
					color: #fff;
				}
				.phones {
					text-align: center;

					.phoneContainer {
						margin-bottom: 12px;

						.city {
							margin: 0 0 4px;
							font-size: 12px;
							line-height: 1.2;
							font-weight: 300;
						}
						.phone {
							font-size: 24px;
							display: block;
							font-weight: 500;
							line-height: 1.2;
							color: #fff;
							text-decoration: none;
							transition: color 0.3s ease;
							cursor: pointer;
						}
					}
				}
			}
			.socials {
				width: 240px;
				order: -1;
				margin: 0 24px 24px;
				margin-left: 48px;

				.socialsContainer {
					width: 100%;

					.title {
						margin: 0 0 12px;
						font-size: 16px;
						font-weight: 400;
						text-align: center;
					}
					.list {
						display: flex;
						flex-flow: row wrap;
						align-items: center;
						justify-content: center;

						.instagram {
							width: 36px;
							height: 36px;
							margin: 0 6px;
							position: relative;
						}
						.instagram::before {
							background-image: url(../../assets/images/icons/instagram.svg);
							opacity: 1;
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							background-size: cover;
							background-position: center center;
							transition: opacity 0.3s ease;
						}
						.instagram:hover::after {
							background-image: url(../../assets/images/icons/instagram_hover.svg);
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							background-size: cover;
							background-position: center center;
							transition: opacity 0.3s ease;
						}

						.vk {
							width: 36px;
							height: 36px;
							margin: 0 6px;
							position: relative;
						}
						.vk::before {
							background-image: url(../../assets/images/icons/vk.svg);
							opacity: 1;
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							background-size: cover;
							background-position: center center;
							transition: opacity 0.3s ease;
						}
						.vk:hover::after {
							background-image: url(../../assets/images/icons/vk_hover.svg);
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							background-size: cover;
							background-position: center center;
							transition: opacity 0.3s ease;
						}

						.facebook {
							width: 36px;
							height: 36px;
							margin: 0 6px;
							position: relative;
						}
						.facebook::before {
							background-image: url(../../assets/images/icons/facebook.svg);
							opacity: 1;
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							background-size: cover;
							background-position: center center;
							transition: opacity 0.3s ease;
						}
						.facebook:hover::after {
							background-image: url(../../assets/images/icons/facebook_hover.svg);
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							background-size: cover;
							background-position: center center;
							transition: opacity 0.3s ease;
						}

						.youtube {
							width: 36px;
							height: 36px;
							margin: 0 6px;
							position: relative;
						}
						.youtube::before {
							background-image: url(../../assets/images/icons/youtube.svg);
							opacity: 1;
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							background-size: cover;
							background-position: center center;
							transition: opacity 0.3s ease;
						}
						.youtube:hover::after {
							background-image: url(../../assets/images/icons/youtube_hover.svg);
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							background-size: cover;
							background-position: center center;
							transition: opacity 0.3s ease;
						}
					}
				}
			}
			.legal {
				order: -2;
				font-weight: 300;
				font-size: 16px;
				line-height: 1.2;
				margin: 0 auto 24px;
			}
		}
		.legalContainer {
			margin-top: 0;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 14px;
			font-weight: 300;

			.copyright {
				flex: 1 0 100%;
				margin-left: 6px;
				margin-right: 6px;
			}
			.item {
				margin-left: 6px;
				margin-right: 6px;
			}
			.mailContainer {
				flex: 1 0 100%;
				margin-left: 6px;
				margin-right: 6px;

				.mail {
					color: inherit;
					text-decoration: none;
				}
			}
		}
	}
}

@media (max-width: 1280px) {
	.footer {
		padding: 24px 0 60px;
		padding-top: 40px;

		.inner {
			max-width: 900px;
			padding: 0 20px;

			.innerContainer {
				.logoContainer {
					order: -1;
				}
				.contacts {
					margin: 0 24px 24px;

					.phones {
						.phoneContainer {
							.city {
								margin: 0 0 2px;
								font-size: 10px;
							}
							.phone {
								font-size: 20px;
							}
						}
					}
				}
				.socials {
					order: -1;
					margin: 0 24px 24px;

					.socialsContainer {
						.list {
							display: flex;
							flex-flow: row wrap;
							align-items: center;
							justify-content: center;

							.instagram {
								width: 32px;
								height: 32px;
								margin: 0 5px;
							}

							.vk {
								width: 32px;
								height: 32px;
								margin: 0 5px;
							}

							.facebook {
								width: 32px;
								height: 32px;
								margin: 0 5px;
							}

							.youtube {
								width: 32px;
								height: 32px;
								margin: 0 5px;
							}
						}
					}
				}
				.legal {
					order: -1;
					margin: 0 24px 24px;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.footer {
		width: 100%;
		padding: 24px 0 60px;

		.inner {
			width: 100%;
			max-width: 480px;
			padding: 0 10px;

			.innerContainer {
				display: block;

				.logoContainer {
					display: none;
				}
				.contacts {
					margin: 0px auto 32px;
				}
				.socials {
					width: 240px;
					order: -1;
					margin: 0 auto 24px;

					.socialsContainer {
						width: 100%;

						.title {
							margin: 0 0 12px;
							font-size: 16px;
							font-weight: 400;
							text-align: center;
						}
						.list {
							display: flex;
							flex-flow: row wrap;
							align-items: center;
							justify-content: center;

							.instagram {
								width: 36px;
								height: 36px;
								margin: 0 6px;
								position: relative;
							}
							.instagram::before {
								background-image: url(../../assets/images/icons/instagram.svg);
								opacity: 1;
								content: '';
								position: absolute;
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
								background-size: cover;
								background-position: center center;
								transition: opacity 0.3s ease;
							}
							.instagram:hover::after {
								background-image: url(../../assets/images/icons/instagram_hover.svg);
								content: '';
								position: absolute;
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
								background-size: cover;
								background-position: center center;
								transition: opacity 0.3s ease;
							}

							.vk {
								width: 36px;
								height: 36px;
								margin: 0 6px;
								position: relative;
							}
							.vk::before {
								background-image: url(../../assets/images/icons/vk.svg);
								opacity: 1;
								content: '';
								position: absolute;
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
								background-size: cover;
								background-position: center center;
								transition: opacity 0.3s ease;
							}
							.vk:hover::after {
								background-image: url(../../assets/images/icons/vk_hover.svg);
								content: '';
								position: absolute;
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
								background-size: cover;
								background-position: center center;
								transition: opacity 0.3s ease;
							}

							.facebook {
								width: 36px;
								height: 36px;
								margin: 0 6px;
								position: relative;
							}
							.facebook::before {
								background-image: url(../../assets/images/icons/facebook.svg);
								opacity: 1;
								content: '';
								position: absolute;
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
								background-size: cover;
								background-position: center center;
								transition: opacity 0.3s ease;
							}
							.facebook:hover::after {
								background-image: url(../../assets/images/icons/facebook_hover.svg);
								content: '';
								position: absolute;
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
								background-size: cover;
								background-position: center center;
								transition: opacity 0.3s ease;
							}

							.youtube {
								width: 36px;
								height: 36px;
								margin: 0 6px;
								position: relative;
							}
							.youtube::before {
								background-image: url(../../assets/images/icons/youtube.svg);
								opacity: 1;
								content: '';
								position: absolute;
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
								background-size: cover;
								background-position: center center;
								transition: opacity 0.3s ease;
							}
							.youtube:hover::after {
								background-image: url(../../assets/images/icons/youtube_hover.svg);
								content: '';
								position: absolute;
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
								background-size: cover;
								background-position: center center;
								transition: opacity 0.3s ease;
							}
						}
					}
				}
				.legal {
					order: -2;
					font-weight: 300;
					font-size: 16px;
					line-height: 1.2;
					margin: 0 auto 24px;
				}
			}
			.legalContainer {
				margin-top: 0;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-size: 14px;
				font-weight: 300;

				.copyright {
					flex: 1 0 100%;
					margin-left: 6px;
					margin-right: 6px;
				}
				.item {
					margin-left: 6px;
					margin-right: 6px;
				}
				.mailContainer {
					flex: 1 0 100%;
					margin-left: 6px;
					margin-right: 6px;

					.mail {
						color: inherit;
						text-decoration: none;
					}
				}
			}
		}
	}
}
