.features {
	margin-top: 160px;
	width: 100%;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	color: #000;

	.inner {
		max-width: 1440px;
		padding: 0 120px;
		position: relative;
		width: 100%;
		margin: 0 auto;

		.listItems {
			max-width: 1180px;
			padding: 0;
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;
			justify-content: space-around;
			flex-direction: row;
			flex-wrap: wrap;
			align-content: center;

			.item {
				margin: 10px;

				.title {
					width: auto;
					font-size: 22px;
					margin-top: 0;
					font-weight: 500;
					line-height: 1.2;
					text-align: center;
				}
				.subtitle {
					max-width: 340px;
					font-size: 18px;
					margin-top: 0;
					font-weight: 400;
					line-height: 1.2;
					text-align: center;
					margin-top: 10px;
				}

				.containerImage {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 5px;

					img {
						display: block;
						width: 180px;
						max-width: 100%;
						height: auto;
						margin: 24px 0;
					}
				}
			}
		}
	}
}

@media (max-width: 1240px) {
	.features {
		margin-top: 100px;
		.inner {
			padding: 0 20px;
			.listItems {
				.item {
					.subtitle {
						max-width: 220px;
					}
				}
			}
		}
		img {
			width: 105px !important;
		}
		p {
			font-size: 12px !important;
		}
	}
}

@media (max-width: 768px) {
	.features {
		margin-top: 55px !important;

		.inner {
			padding: 0 10px;
		}
	}
}
