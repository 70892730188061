.header {
	position: fixed;
	z-index: 50;
	top: 0;
	width: 100%;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	background-color: #fff;
	box-shadow: 0 -2px 10px 0 rgb(0 0 0 / 50%);

	.inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		align-content: center;
		height: 118px;
		max-width: 1440px;
		padding: 0 160px;
		position: relative;
		width: 100%;
		margin: 0 auto;
		min-width: 320px;

		.container {
			width: 194px;
			height: 59px;

			.logo {
				margin-left: 5px;
				display: block;
				width: auto;
				max-width: 100%;
				height: auto;
				max-height: 100%;
			}
		}
		.contact {
			cursor: pointer;
			text-align-last: end;
			.city {
				padding: 0 0 2px;
				color: #000;
				font-size: 10px;
				font-weight: 400;
				line-height: 1.1;
				background: 0 0;
			}
			.phone {
				color: #d60623;
				font-size: 16px;
				font-weight: 500;
				line-height: 1.15;
			}
		}
	}
}

.headerVisible {
	visibility: visible;
	transition: all 0.5s;
}
.headerHidden {
	visibility: hidden;
	transition: all 0.5s;
	transform: translateY(-100%);
}

@media (max-width: 1240px) {
	.header {
		.inner {
			justify-content: space-between;
			height: 73px;
			padding: 0 55px;

			.container {
				width: 140px;
				height: auto;
			}
			.contact {
				margin-right: 35px;
				.city {
					font-size: 7px;
				}
				.phone {
					font-size: 12px;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.header {
		.inner {
			justify-content: center;
			height: 73px;

			.container {
				width: 194px;
				height: auto;

				.logo {
					margin-left: 10px;
				}
			}
			.iconPhone {
				position: relative;
				left: 15%;
				cursor: pointer;
				width: 36px;
				height: 26px;
				background-image: url(../../assets/images/phone.svg);
				background-size: 24px;
				background-repeat: no-repeat;
			}
			.contact {
				display: none;
				.city {
					font-size: 7px;
				}
				.phone {
					font-size: 12px;
				}
			}
		}
	}
}
