.item {
	width: auto;
	margin: 0 25px;
	position: relative;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease,
		-moz-transform 0.3s ease;
	border-radius: 9px;
	border: 1px solid rgba(151, 151, 151, 0.3);
	outline: 0;
	.image {
		cursor: pointer;
		.img {
			display: block;
			width: auto;
			max-width: 100%;
			height: auto;
			max-height: 100%;
			margin: 0 auto;
			border-radius: 9px;
		}
	}

	.label {
		padding: 14px 5px;
		font-size: 14px;
		color: #000;
		font-weight: 400;
		text-decoration: none;
		text-align: center;
	}
}
.item:hover {
	background: #cc0e2892;
}

@media (max-width: 1355px) {
	.item {
		.label {
			font-size: 15px;
		}
	}
}

@media (max-width: 1240px) {
	.item {
		margin: 0 5px;

		.label {
			padding: 10px 5px;
			font-size: 13px;
		}
	}
}
