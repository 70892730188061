@font-face {
	font-family: Roboto;
	font-weight: 100;
	font-style: normal;
	src: url(../../assets/fonts/Robotolight.woff2) format('woff2'),
		url(../../assets/fonts/Robotothin.woff) format('woff');
	font-display: swap;
}
@font-face {
	font-family: Roboto;
	font-weight: 300;
	font-style: normal;
	src: url(../../assets/fonts/Robotolight.woff2) format('woff2'),
		url(../../assets/fonts/Robotolight.woff) format('woff');
	font-display: swap;
}
@font-face {
	font-family: Roboto;
	font-weight: 400;
	font-style: normal;
	src: url(../../assets/fonts/Roboto.woff2) format('woff2'),
		url(../../assets/fonts/Roboto.woff) format('woff');
	font-display: swap;
}
@font-face {
	font-family: Roboto;
	font-weight: 500;
	font-style: normal;
	src: url(../../assets/fonts/Robotomedium.woff2) format('woff2'),
		url(../../assets/fonts/Robotomedium.woff) format('woff');
	font-display: swap;
}
@font-face {
	font-family: Roboto;
	font-weight: 700;
	font-style: normal;
	src: url(../../assets/fonts/Robotobold.woff2) format('woff2'),
		url(../../assets/fonts/Robotobold.woff) format('woff');
	font-display: swap;
}
@font-face {
	font-family: Roboto;
	font-weight: 900;
	font-style: normal;
	src: url(../../assets/fonts/Robotoblack.woff2) format('woff2'),
		url(../../assets/fonts/Robotoblack.woff) format('woff');
	font-display: swap;
}
@font-face {
	font-family: Carolina;
	font-weight: 400;
	font-style: normal;
	src: url(../../assets/fonts/Carolina.woff2) format('woff2'),
		url(../../assets/fonts/Carolina.woff) format('woff');
	font-display: swap;
}
.app {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	line-height: 1.42857143;
}
