.comments {
	color: #000;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	background-color: #fbfbfb;
	width: 100%;
	padding-bottom: 76px;
	padding-top: 64px;

	.inner {
		position: relative;
		width: 100%;
		margin: 0 auto;
		min-width: 320px;
		max-width: 1440px;
		padding: 0 120px;

		.titleContainer {
			text-align: center;
			margin-bottom: 50px;

			.title {
				font-size: 24px;
				position: relative;
				display: inline-block;
				margin: 0;
				font-weight: 400;
			}
			.title::after {
				content: '';
				width: 180px;
				bottom: -5px;
				position: absolute;
				height: 2px;
				left: 50%;
				transform: translateX(-50%);
				background-color: #d60623;
			}
		}
		.slider {
			max-width: 920px;
			margin: 0 auto;
			user-select: none;
			touch-action: pan-y;
			position: relative;
			display: block;

			.btnPrev {
				cursor: pointer;
				outline: 0;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				background-image: url(../../assets/images/arrow_red.svg);
				font-size: 0;
				background-color: transparent;
				border: none;
				z-index: 6;
				position: absolute;
				top: 50%;
				left: -80px;
				width: 20px;
				height: 32px;
				transform: translateY(500%) rotate(180deg);
			}
			.btnNext {
				cursor: pointer;
				outline: 0;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				background-image: url(../../assets/images/arrow_red.svg);
				font-size: 0;
				background-color: transparent;
				border: none;
				z-index: 6;
				position: absolute;
				top: 50%;
				right: -80px;
				width: 20px;
				height: 32px;
				transform: translateY(500%);
			}
		}
	}
}

@media (max-width: 1240px) {
	.comments {
		background-color: #fbfbfb;
		padding-bottom: 24px;
		padding-top: 40px;

		.inner {
			max-width: 900px;
			padding: 0 20px;

			.titleContainer {
				.title {
					font-size: 18px;
				}
				.title::after {
					width: 120px;
				}
			}
			.slider {
				max-width: 608px;

				.btnPrev {
					left: -60px;
					width: 12px;
					height: 20px;
				}

				.btnNext {
					right: -60px;
					width: 12px;
					height: 20px;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.comments {
		padding-bottom: 40px;
		padding-top: 24px;

		.inner {
			max-width: 480px;
			padding: 0 10px;

			.titleContainer {
				margin-bottom: 30px;

				.title {
					font-size: 18px;
				}
				.title::after {
					width: 120px;
				}
			}
			.slider {
				max-width: 400px;

				.btnPrev {
					top: 20%;
					left: 0;
					width: 12px;
					height: 20px;
					transform: translateY(500%) rotate(180deg);
				}
				.btnNext {
					top: 20%;
					right: 0;
					width: 12px;
					height: 20px;
				}
			}
		}
	}
}
