.background {
	background: #00000085;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);
	z-index: 25;
}
.modal {
	overflow-y: auto;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	line-height: 1.42857143;
	background: #dcdbdb;
	color: #131313;
	border-radius: 6px;
	border: none;
	background-color: #fbfbfb;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 50%;
	height: 100%;
	transform: translate(-50%, -50%);
	box-shadow: 0 4px 4px rgba(8, 6, 6, 0.25);
	z-index: 100;
	animation: show 500ms ease-in-out;

	.form {
		padding: 5px 10px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;

		.close {
			font-size: 30px;
			font-weight: 100;
			appearance: none;
			align-self: flex-end;
			padding: 0;
			cursor: pointer;
			background: 0 0;
			border: 0;
			color: #000;
			text-shadow: 0 1px 0 #fff;
			opacity: 0.2;
		}

		.container {
			.image {
				width: 100%;
			}
		}
	}
}

@keyframes show {
	from {
		top: 70%;
		opacity: 0;
	}
	to {
		top: 50%;
		opacity: 1;
	}
}

@media (max-width: 1240px) {
	.modal {
		width: 85%;
		height: 95%;

		.form {
			padding: 5px 10px;
		}
	}
}

@media (max-width: 768px) {
	.modal {
		width: 95%;
		height: 75%;

		.form {
			padding: 5px;

			.title {
				font-size: 16px;
			}
			.description {
				font-size: 12px;
			}
		}
	}
}
